<template>

  <div>

    <b-card>

      <h2 class="mb-2">
        Search Filter
      </h2>

      <!-- Table Top -->
      <b-row>

        <b-col
          cols="12"
          md="3"
          class="w-100"
        >
          <v-select
            v-model="filterDataProgram"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterProgramOptions"
            :clearable="false"
            :placeholder="'Select Program'"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="w-100"
        >
          <v-select
            v-model="filterDataVisibility"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterVisibilityOptions"
            :clearable="false"
            :placeholder="'Select Visibility'"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="w-100"
        >
          <v-select
            v-model="filterDataStatus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterStatusOptions"
            :clearable="false"
            :placeholder="'Select Status'"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <div class="d-flex align-items-center">
            <b-button
              class="ml-1"
              variant="primary"
              @click="clearFilters"
            >
              <span class="mr-25 align-middle">Reset filters</span>
            </b-button>
          </div>
        </b-col>

      </b-row>

    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1 mb-0">Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :value="perPage"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
              @input="updatePerPage"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="w-25"
              />

              <b-button
                class="ml-1"
                variant="primary"
                :to="{ name: 'admin-blog-add' }"
              >
                <span class="mr-25 align-middle">Create Post</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="fetchBlogList"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Loader -->
        <template #table-busy>
          <TableSpinner />
        </template>

        <!-- Column: title -->
        <template #cell(title)="data">
          <b-avatar
            class="mr-1"
            size="32"
            :src="data.item.image"
          />
          <b-link
            :to="{ name: 'admin-cms-edit', params: { id: data.item.id } }"
          >{{ data.item.title }}</b-link>
        </template>

        <!-- Column: visible -->
        <template #cell(visible)="data">
          {{ data.item.visibility }}
        </template>

        <!-- Column: category -->
        <template #cell(category)="data">
          <span
            v-for="(category, index) in data.item.categories"
            :key="index"
            class="mr-1"
          >{{ category }}</span>
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-for="option in actionOptions"
              :key="option.value"
              :to="{ name: option.link, params: { id: data.item.id } }"
            >
              <span>{{ option.title }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="data.item.visibility !== 'Public'"
              @click="makePublic(data.item)"
            >
              <span>Publish</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.visibility !== 'Private'"
              @click="makePrivate(data.item)"
            >
              <span>Hide</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="deletePost(data.item.id)"
            >
              <span>Delete Post</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems && perPage !== 'all'"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination,
  BDropdown, BDropdownItem, BAvatar, BBadge, BButton, BLink,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'

import vSelect from 'vue-select'
import store from '@/store'
import storeModule from '@/views/admin/blog/blogStoreModule'
import useBlogList from '@/views/admin/blog/blog-list/useBlogList'
import useBlogSetting from '@/views/admin/blog/useBlogSetting'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import { DEFAULT_PER_PAGE } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BBadge,
    BButton,
    BLink,
    vSelect,

    TableSpinner,
  },
  data() {
    return {

      filterStatusOptions: [],
      filterProgramOptions: [],
      aboutId: 1,
      aboutText: '',
    }
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-cms'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      updatePost,
      getAbout,
      setAbout,
    } = useBlogSetting()

    const {
      fetchBlogList,
      tableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      searchQuery,
      filterVisibilityOptions,
      sortBy,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,

      filterDataProgram,
      filterDataVisibility,
      filterDataStatus,

      statusFilter,
      actionOptions,

      refetchData,

      deletePost,

      resolveStatusVariant,
      clearFilters,
    } = useBlogList(root)

    return {
      fetchBlogList,
      tableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      sortBy,
      searchQuery,
      filterVisibilityOptions,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,

      filterDataProgram,
      filterDataVisibility,
      filterDataStatus,

      statusFilter,
      actionOptions,

      refetchData,

      updatePost,
      deletePost,

      getAbout,
      setAbout,

      resolveStatusVariant,
      clearFilters,
    }
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.refetchData()
    },
  },
  async created() {
    await this.$store.dispatch('app-cms/fetchPostStatuses')
      .then(response => {
        this.filterStatusOptions = response.data.data
      })

    await this.getAbout(this.aboutId)
      .then(response => {
        this.aboutText = response.text
      })

    this.filterProgramOptions = await this.$store.dispatch('app-cms/fetchProgramList')
      .then(response => response.data.data.reduce((arr, item) => {
        arr.push({ id: item.id, label: item.name })
        return arr
      }, []))

    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    makePublic(post) {
      const updatedPost = {
        id: post.id,
        visibility: 'Public',
        status: 'Published',
      }
      this.updatePost(updatedPost)
      this.refetchData()
    },
    makePrivate(post) {
      const updatedPost = {
        id: post.id,
        visibility: 'Private',
      }
      this.updatePost(updatedPost)
      this.refetchData()
    },
    updateAbout() {
      const queryParams = {
        id: this.aboutId,
        text: this.aboutText,
      }
      this.setAbout(queryParams)
    },
    updatePerPage(val) {
      localStorage.setItem('blogPerPage', val)
      this.perPage = val
    },
    setFilterParams() {
      const query = { ...this.$route.query }
      const defaultPerPage = localStorage.getItem('blogPerPage')
      this.currentPage = Number(query.currentPage) || 1
      this.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
      this.searchQuery = query.searchQuery || ''
      this.filterDataProgram = query.filterDataProgram || ''
      this.filterDataVisibility = query.filterDataVisibility || ''
      this.filterDataStatus = query.filterDataStatus || ''
    },
  },
}
</script>

<style lang="scss" scoped>
  .table-header {

    label {
      margin-bottom: 0;
    }
  }

  .badge {
    text-transform: uppercase;
  }

  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/quill.scss';
</style>

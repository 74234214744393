import { ref, watch } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import store from '@/store'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useBlogList(root) {
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'title', label: 'Title', sortable: true },
    { key: 'visible', label: 'Visible', sortable: true },
    { key: 'category', label: 'Category', sortable: true },
    { key: 'program', label: 'recent program', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]

  const defaultPerPage = Number(localStorage.getItem('blogPerPage'))
  const perPage = ref(defaultPerPage || DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const filterVisibilityOptions = ['Public', 'Private']
  const searchQuery = ref('')
  const filterDataProgram = ref('')
  const filterDataVisibility = ref('')
  const filterDataStatus = ref('')
  const sortBy = ref('posts.created_at')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  let debounceTimeout = ref(null)

  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const clearFilters = () => {
    filterDataProgram.value = ''
    filterDataVisibility.value = ''
    filterDataStatus.value = ''
  }

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, filterDataProgram, filterDataVisibility, filterDataStatus],
    ([currentPageVal, perPageVal, searchQueryVal, filterDataProgramVal, filterDataVisibilityVal, filterDataStatusVal],
      [currentPagePrevVal, perPagePrevVal, searchQueryPrevVal, filterDataProgramPrevVal, filterDataVisibilityPrevVal, filterDataStatusPrevVal]) => {
      if (currentPageVal !== currentPagePrevVal) {
        pushStateFiltersParams(root, 'admin-cms', 'currentPage', currentPageVal)
      }
      if (perPageVal !== perPagePrevVal) {
        pushStateFiltersParams(root, 'admin-cms', 'perPage', perPageVal)
      }
      if (searchQueryVal !== searchQueryPrevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(root, 'admin-cms', 'searchQuery', searchQueryVal)
        }, 250)
      }
      if (filterDataProgramVal !== filterDataProgramPrevVal) {
        pushStateFiltersParams(root, 'admin-cms', 'filterDataProgram', filterDataProgramVal)
      }
      if (filterDataVisibilityVal !== filterDataVisibilityPrevVal) {
        pushStateFiltersParams(root, 'admin-cms', 'filterDataVisibility', filterDataVisibilityVal)
      }
      if (filterDataStatusVal !== filterDataStatusPrevVal) {
        pushStateFiltersParams(root, 'admin-cms', 'filterDataStatus', filterDataStatusVal)
      }
    })
  const fetchBlogList = (ctx, callback) => {
    const query = { ...root.$route.query }
    const payloadData = {
      perPage: perPage.value,
      page: query.currentPage || 1,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    }
    if (searchQuery.value) {
      payloadData.title = searchQuery.value
    }
    if (filterDataStatus.value) {
      payloadData.status = filterDataStatus.value
    }
    if (filterDataProgram.value) {
      payloadData.programId = filterDataProgram.value.id
    }
    if (filterDataVisibility.value) {
      payloadData.visibility = filterDataVisibility.value
    }
    store
      .dispatch('app-cms/fetchBlogList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta

        callback(data)
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching posts list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deletePost = ID => axios
    .delete(`auth/posts/${ID}`)
    .then(() => {
      refetchData()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting post',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting post',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'Published') return 'success'
    if (status === 'Draft') return 'warning'
    return 'primary'
  }

  const actionOptions = [
    { value: 'edit', title: 'Edit', link: 'admin-cms-edit' },
  ]

  return {
    fetchBlogList,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    filterDataProgram,
    filterDataVisibility,
    filterDataStatus,
    searchQuery,
    filterVisibilityOptions,
    sortBy,
    totalItems,
    isSortDirDesc,
    refInvoiceListTable,

    statusFilter,
    actionOptions,

    resolveStatusVariant,

    deletePost,

    refetchData,
    clearFilters,
  }
}
